// background colors
$bg-gray: #f8f8f8;
$bg-white: #ffffff;

// text color
$text-black: #010103;
$text-orange: #ff4d30;
$btn-black: #161616;
$text-gray: #706f7b;

